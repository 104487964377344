import React from 'react';
import { graphql, Link } from 'gatsby';
import * as b from "../components/bootstrap.module.css"
import BlogContent from '../components/blog-content';
import TableOfContents from '../components/table-of-contents';
import Breadcrumbs from "../components/breadcrumbs"
import Icon from "../components/icon"
import resolveUrl from '../utils/urls/resolve-url';
import Layout from '../components/layout';
import Posts from '../components/posts';

import BaseHead from '../components/base-head';
import siteMetadata from "../utils/site-metadata";

export const Head = ({...props}) => (
  <BaseHead {...props} description={props.data?.mysqlTerms?.description}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetadata.domain}/social-media/term/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
);

const Credits = ({ 
  data: { allMysqlTerms, subCategories, mysqlTerms, allMysqlBlog },
  pageContext: { breadcrumbs },
  location: { pathname },
}) => {
  return (
    <Layout pathname={pathname}>
      <div>
        <section className={`intro-products2 ${b.py4} ${b.pyMd5} `}>
          <div className={[b.container, "breadcrumbs-mobile-products", b.pb3,"scroll-inner"].join(" ")}>
            <Breadcrumbs items={breadcrumbs}/>
          </div>
          <div className={`${b.container} ${b.pb2} ${b.positionRelative}`}>
            <div className={b.row}>
              <div className={b.colLg12}>
                <h1 className={`big-title ${b.pb3} ${b.w75} ${b.ptLg5}`}>
                  {mysqlTerms.title}
                </h1>

                <div className={`intro-calculator ${b.p3} ${b.row} ${b.mx0}`}>
                  <div className={[b.colLg12,'blueToWhite',b.px1].join(' ')}>Jakiego kredytu poszukujesz?</div>
                    { allMysqlTerms.nodes.map(({ term_normalized, terms_path, term, cluster}, i) => (
                      <div className={[b.colLg3,b.mt2,'colflex20',b.px1].join(' ')} key={i}>
                        <Link to={resolveUrl(terms_path, term_normalized)}>
                          <div className={['intro-calculator',b.py2,b.px3,b.dFlex,b.alignItemsCenter].join(' ')}>
                            <div>
                              {cluster === 'credits_1' ? <Icon size={40} icon="Mortgage-house" className={[b.mr2,'icon-blog'].join(' ')} /> : ''}
                              {cluster === 'credits_2' ? <Icon size={40} icon="Cash-loan" className={[b.mr2,'icon-blog'].join(' ')} /> : ''}
                              {cluster === 'credits_3' ? <Icon size={40} icon="Car-loan" className={[b.mr2,'icon-blog'].join(' ')} /> : ''}
                              {cluster === 'credits_4' ? <Icon size={40} icon="Consolidation-loan" className={[b.mr2,'icon-blog'].join(' ')} /> : ''}
                              {cluster === 'cards' ? <Icon size={40} icon="Credit-cards" className={[b.mr2,'icon-blog'].join(' ')} /> : ''}
                            </div>
                            <span className='font-size-13'>{term}</span>
                          </div>
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {subCategories.nodes.length > 0 && 
        <div className={[b.container, b.mt4,b.mtLg5].join(' ')}>
          <h2 className={["title", b.dBlock, b.mb4].join(' ')}>Tematy w kategorii: {mysqlTerms.term}</h2>
          
          <div className={['subcategory-row', b.row].join(' ')}>
            {subCategories.nodes.map(({path,term, term_normalized}, i) => (
              <div className={[b.colMd6, b.colLg4, b.colXl3, b.px1].join(' ')} key={i}>
                <Link to={resolveUrl(path, term_normalized)}>
                  <div className={['category-box',b.mt2,b.dFlex].join(' ')}>
                    <div className='category-bl'/>
                    <div className={b.p3}>{term}</div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      }

      <div className={`${b.container} ${b.pt3} ${b.ptLg4}`}>
        <div className={["blog-content", b.row, 'blueToGray', 'dark-li'].join(" ")}>
          <div className={[b.colXl4, b.mb3, b.orderXlLast].join(' ')}>
            <TableOfContents selector=".post-content h2[id]" />
          </div>
          
          <div className={[ b.colXl8, 'dark-theme-listing'].join(' ')}>
          {mysqlTerms.description && (
            <BlogContent content={mysqlTerms.description} className={[b.pr1, b.mb4].join(" ")}/>
          )}
          {mysqlTerms.description_alt && (
            <BlogContent content={mysqlTerms.description_alt} className={[b.pr1, b.mb4].join(" ")}/>
          )}
          {mysqlTerms.description_additional && (
            <BlogContent content={mysqlTerms.description_additional} className={[b.pr1, b.mb4].join(" ")}/>
          )}
          </div>
        </div>
      </div>

      {allMysqlBlog.nodes.length !== 0 && ( <>
        <section className={`${b.container} ${b.pt3} ${b.ptLg4} ${b.pb4} ${b.pbLg5} ${b.mbLg5}`}>
          <h2 className={["title", b.dBlock, b.mb4,b.mt3].join(' ')}>Treści powiązane z {mysqlTerms.term}</h2>
          <Posts items={allMysqlBlog.nodes} />
        </section>
      </>)}
    </Layout>
  )
}

export default Credits

export const query = graphql `
query CreditsQuery($mysqlId: Int!, $withoutUrls: [String]) {
  allMysqlTerms(filter: {cluster: {regex: "/^(credits_.+|cards)$/"}}, sort: {fields: sort}, limit: 5) {
    nodes {
      term_normalized
      terms_path: path
      cluster
      term
    } 
  }
  subCategories: allMysqlTerms(filter: {term_normalized: {nin: $withoutUrls}, mysqlParent: {eq: $mysqlId}, is_article: {ne: null}, template: {ne: "products"}, menu: {eq: null}}) {
    nodes {
      term
      term_normalized
      path
    }
  }
  allMysqlBlog(filter: {url: {nin: $withoutUrls}, term_id: {eq: $mysqlId}, is_category: {eq: null}, description: {ne: null}, status: {eq: "publish"}}) {
    nodes {
      ...PostData
    }
  }
  allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "term" }}) {
    nodes {
      ...SeoData
    }
  }
  mysqlTerms(mysqlId: {eq: $mysqlId}) {
    description_additional
    description_alt
    description
    title
    term
  }
}

`