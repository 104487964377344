import React from 'react';
import * as b from "../../bootstrap.module.css"
import Icon from '../../icon'

const redirect = () => {
    window.open("https://out.akredo.pl/wniosek/kredyt", '_blank');
};

const WnioskerSection = () => {
    return (
        <div className={[b.card, b.px3, b.plXl4, b.prXl0, b.overflowHidden].join(' ')}>
            <div className={[b.row, b.justifyContentAround].join(' ')}>
                <div className={[b.colXl6, b.orderXlLast, b.prXl0].join(' ')}>
                    <img width="380" height="403" src="/assets/offers.webp" style={{ width: 'auto', objectFit: 'cover', objectPosition: 'center' }}  />
                </div>

                <div className={[b.colXl6, b.dFlex, b.flexColumn, b.pb3, b.prXl0, b.pyXl4].join(' ')}>
                    <div style={{ flex: '1 1' }}>
                        <strong className={["font-size-24", b.dBlock, b.mb2, b.pb1].join(' ')}>
                            Wszystkie oferty w <span style={{ color: '#4EAA97' }}>jednym miejscu!</span>
                        </strong>
                        
                        <p className={["font-size-13", b.mt0].join(' ')}>
                            Nie trać czasu na sprawdzanie ofert i warunków wszystkich pożyczkodawców. 
                            Sami dobierzemy Ci najlepszą ofertę! <strong>Skorzystaj z Wnioskera za darmo!</strong>
                        </p>
                        
                        <div className={['blueToWhite','font-size-13', b.dFlex, b.alignItemsCenter, b.my2].join(' ')}>
                            <Icon size={16} icon="Checked-circle" className={[b.mr2,'index-icon'].join(' ')} />
                            Wszystko w jednym miejscu
                        </div>
                        
                        <div className={['blueToWhite','font-size-13', b.dFlex, b.alignItemsCenter, b.mb2].join(' ')}>
                            <Icon size={16} icon="Checked-circle" className={[b.mr2,'index-icon'].join(' ')} />
                            Całkowicie za darmo
                        </div>
                        
                        <div className={['blueToWhite','font-size-13', b.dFlex, b.alignItemsCenter, b.mb3].join(' ')}>
                            <Icon size={16} icon="Checked-circle" className={[b.mr2,'index-icon'].join(' ')} />
                            W 5 minut
                        </div>
                    </div>   

                    <button onClick={redirect} style={{ fontSize: '16px', width: 'fit-content' }} className={[b.btn, 'btn-red','btn-normal', b.mx0, b.fontWeightNormal].join(' ')}>
                        <Icon size={20} color="#FFFFFF" icon="Taking-money" className={['icon-blog', b.mr2].join(' ')} />
                        Wybierz najlepszą ofertę
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WnioskerSection;