import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import * as b from "../components/bootstrap.module.css";
import scrollTo from "gatsby-plugin-smoothscroll";
import { SCROLL_CLASS, DIRECTION_DOWN_CLASS, DIRECTION_UP_CLASS, } from "../utils/classes";
import useMedia from "../hooks/useMedia";
import Icon from "../components/icon.tsx";

let idTimeout = null;

const TableOfContents = ({ selector, isOnlyMobile, withoutCalculator, children }) => {
  const isDesktopResolusion = useMedia(["(max-width: 1200px)"], [true], false);
  const [isOpen, setIsOpen] = useState(false);
  const [listItems, setListItems] = useState([]);

  useEffect(() => {
    const allH2 = document.querySelectorAll(selector.slice(0, -4));
   
    allH2.forEach((h2, i)=> {
      if (h2.textContent.trim().length !== 0) {
        h2.setAttribute('id', `section-title-${i+1}`);
      }
    })
  }, [])

  const scroll = (id) => {

    clearTimeout(idTimeout);
    setIsOpen(false);
    document.body.classList.remove(DIRECTION_UP_CLASS);
    document.body.classList.add(SCROLL_CLASS, DIRECTION_DOWN_CLASS);
    scrollTo(`#${id}`);
    idTimeout = setTimeout(
      () => document.body.classList.remove(SCROLL_CLASS),
      2000
    );
  };

  useEffect(() => {
    if (!selector.length) {
      return;
    }
    const allH2 = document.querySelectorAll(selector);
    setListItems(
      Object.values(allH2).map(({ textContent, id }, i) => (
        <li key={i} >
          <button onClick={() => scroll(id)} className={["btn-hidden",'blackToWhite'].join(' ')} data-id={id}>
            {textContent}
          </button>
        </li>
      ))
    );
  }, [selector]);

  if (listItems.length <= 1) {
    return null;
  }

  return !isDesktopResolusion && !isOnlyMobile ? (
    <div className="toc-wrapper sticky">
      <div className={["toc", b.card, "table-of-contents",b.p3,b.mt3].join(' ')}>
        <h4 className="toc-title">Spis treści</h4>
        
        <ul className={['dark-theme-listing','blackToWhite'].join(' ')}>{listItems}</ul>
      </div>
      
      {children}

      {!withoutCalculator && (
        <div className={b.mt3}>
          <Calculator />
        </div>
      )}
    </div>

) : (
  <>
    <div className={["mobile-toc", b.dXlNone, isOpen ? "is-open" : ""].join(" ")}>
      <button className={["mobile-toc-angles", "btn-hidden"].join(" ")} onClick={() => setIsOpen(!isOpen)}>
        <span className={[b.mr2,'blackToWhite'].join(' ')}>{isOpen ? "Zwiń" : "Spis treści"}</span>
        {isOpen ? (
          <Icon key="1" size={40} icon="Angle-down" className='iconToWhite'/>
          ) : (
          <Icon key="2" size={40} icon="Angle-up" className='iconToWhite'/>
        )}
      </button>

      <div className={["mobile-toc-container", b.container].join(" ")}>
        <ul className={[b.my0, b.mb3,'dark-theme-listing'].join(" ")}>{listItems}</ul>
      </div>

      {children}
    </div>

    {!withoutCalculator && (
      <Calculator />
    )}
  </>
);
};

const Calculator = () => {
  const inputRef = useRef() 
  const [rangeAmountValue, setRangeAmountValue] = useState(12_000);
  const formatNumber= (num)=> {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
  }
  let spanWidth = 113
  let intValue = parseInt(rangeAmountValue, 10);

  if(intValue <=9999){
    spanWidth = 103
  }
  

  return (
    <div className={['home-page-app-box', 'home-calculator',b.p3].join(' ')} style={{ 
      borderRadius: '8px',
      border: '1px solid #FAAB2D',
      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
      '--min': 1000, 
      '--max': 150_000, 
      '--value': rangeAmountValue
    }}>
      <div className={[b.dFlex,b.alignItemsCenter].join(' ')}>
        <label htmlFor="amount" className={['blackToWhite','font-size-18', b.mb0].join(' ')} style={{ fontWeight: '500' }}>
          Weź szybki kredyt z Akredo!        
        </label>
      </div>
      <p className={[b.mt1, b.mb3, b.mbMd4,'font-size-13','blackToWhite'].join(' ')}>
        Sprawdź jak łatwo można dostać pożyczkę!
      </p>

      <input
        ref={inputRef}
        aria-label="slider"
        value={rangeAmountValue}
        className='styled-slider slider-progress'
        name="amount"
        type="range" 
        min={1000}
        max={150_000} 
        step={1000}
        onChange={() => {
          // 
        }}
        onInput={e => {
          setRangeAmountValue(e.target.value);

          e.target.style.setProperty('--value', e.target.value);
        }} 

      />

      <span style={{ position: 'relative', display: 'block', width: `${spanWidth}px`, textAlign: 'center', fontSize: '15px', }} className='ui-slider-handle'>
        <Icon size={20} icon="Angle-left" className={[b.mr2,'icon-blog'].join(' ')}/>
        {formatNumber(rangeAmountValue)}
        <Icon size={20} icon="Angle-right" className={[b.ml2,'icon-blog'].join(' ')} />
      </span>

      <div className={[b.dFlex,b.justifyContentBetween,'blackToWhite'].join(' ')}>
        <small>1000</small>
        <small>150 000</small>
      </div>
      <a href={`https://out.akredo.pl/wniosek/kredyt?amount=${rangeAmountValue}`} target='_blank' className={[b.btn,'btn-success','btn-normal',b.mtLg4,b.mt3,b.fontWeightNormal].join(' ')}>
        <Icon size={20} color="#FFFFFF" icon="Taking-money" className={b.mr2} />
        Wypełnij wniosek
      </a>

      <span className={[b.dBlock, b.textCenter, b.mt2, 'font-size-13','blackToWhite'].join(' ')}>
        Wnioskowanie w <b style={{ fontWeight: 600 }}>5 minut</b> całkowicie za darmo!
      </span>
    </div>
  )
}


TableOfContents.propTypes = {
  selector: PropTypes.string.isRequired,
  isOnlyMobile: PropTypes.bool,
};

export default TableOfContents;