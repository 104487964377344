import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { navigate } from "gatsby";

import ConverterTableToMobile from "../components/converter-table-to-mobile";
import WnioskerSection from './shortcodes/partials/wniosker-section';

const outRegExp = /href="\/?out\/(.*)\/hdr\/?"/gi;
const styleRegExp = /(style="(.*?)")/gms;

const BlogContent = ({ content, className }) => {
  const contentRef = useRef(null);

  content = content
    .replaceAll(outRegExp, 'href="https://out.akredo.pl/$1"')
    .replaceAll(styleRegExp, '');

  useEffect(() => {
    ConverterTableToMobile(contentRef.current);

    const wnioskerSection = contentRef.current.querySelector('.post-content #wniosker-section-shortcode');

    if (wnioskerSection) {
      ReactDOM.render(<WnioskerSection />, wnioskerSection)
    }
  }, []);

  return (
    <div 
      dangerouslySetInnerHTML={{ __html: content }} 
      className={["post-content blackToGray", className].join(" ")} 
      ref={contentRef}
      onClickCapture={(e) => {
        if (e.target.tagName === 'A' && e.target.href.includes('//akredo.pl')) {
          e.preventDefault();

          navigate(e.target.pathname);
        }
      }}
    />
  );
};

export default BlogContent;