import React from "react";
import ReactDOM from "react-dom";

const converter = (content) => {
  for (const table of content.querySelectorAll(".post-content table")) {
    const columns = [...table.querySelectorAll("thead tr th")];
    const rows = [...table.querySelectorAll("tbody tr")].map((row) => row.querySelectorAll("td"));

    if (columns.length > 2 && rows.length) {
      const tag = document.createElement("div");
      table.parentNode.appendChild(tag);
      table.classList.toggle("converted-table");

      ReactDOM.render(<MobileTable className={table.className} columns={columns} rows={rows} />, tag);
    }
  }
};

const MobileTable = ({ columns, rows, className }) => {
  return rows.map((r, idxr) => (
    <table className={[className, "converter"].join(" ")} key={`table-${idxr}`}>
      <tbody>
        {columns.map((h, idxh) => (
          <tr key={`header-${idxh}`}>
            <th dangerouslySetInnerHTML={{ __html: h?.innerHTML || "" }}></th>
            <td dangerouslySetInnerHTML={{ __html: r[idxh]?.innerHTML || "" }}></td>
          </tr>
        ))}
      </tbody>
    </table>
  ));
};

export default converter
